import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import innovativeImage from './Innovative.jpg'; // Make sure this path is correct

const Agriculture = () => {
  return (
    <>
      {/* Innovative Image Section */}
      <Container
        style={{
          backgroundImage: `url(${innovativeImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%', // Full width
          height: '200px', // Set a specific height
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '5px', // optional for aesthetics
          marginBottom: '20px', // Space between image and text 
        }}
      >
        <h1 style={{ fontSize: '2rem', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px' }}>
          Innovative Tech Solutions
        </h1>
      </Container>

      <Container style={{ padding: '2rem', color: '#fff', backgroundColor: '#243b55' }}>
        <h1>Agriculture Use Cases</h1>

        <Row style={{ margin: '2rem 0' }}>
          <Col md={6}>
            <div>
              <h2>Generative AI / LLM</h2>
              <ul>
                <li>Virtual farm advisor</li>
                <li>Crop diagnosis and recommendations</li>
                <li>Pest identification and disease management</li>
              </ul>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <h2>NLP</h2>
              <ul>
                <li>Customer sentiment analysis</li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row style={{ margin: '2rem 0' }}>
          <Col md={6}>
            <div>
              <h2>Big Data & Predictive Analytics</h2>
              <ul>
                <li>Crop yield prediction</li>
                <li>Monitoring livestock�s health and management</li>
              </ul>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <h2>Computer Vision</h2>
              <ul>
                <li>Crop and soil health monitoring with object detection</li>
                <li>Crop field security</li>
                <li>Inventory shrinkage reduction</li>
                <li>Intelligent spraying & irrigation</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Agriculture;