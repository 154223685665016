import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import amenLogo from './amen-logo.png'; // Adjust the path as needed

const Header = () => {
  return (
    <Container>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/">
          <img
            src={amenLogo}
            alt="Al-AMEN Logo"
            style={{ width: '50px', height: 'auto', marginRight: '8px' }} // Adjust size and margin as needed
          />
          TECHNOLOGY
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <NavDropdown title="Company" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/about">About Us</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/partners">Partners</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/careers">Careers</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Solutions" id="basic-nav-dropdown-solutions">
              <NavDropdown.Item as={Link} to="/solutions/ai">AI</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/solutions/blockchain">Blockchain</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Usecases" id="basic-nav-dropdown-usecases">
              <NavDropdown.Item as={Link} to="/usecases/fintech">FinTech</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/usecases/manufacturing">Manufacturing</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/usecases/healthcare">Healthcare</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/usecases/ecommerce">E-commerce</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/usecases/retail">Retail</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/usecases/logistics">Logistics</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/usecases/marketing-sales">Marketing and Sales</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/usecases/sport-wellness">Sport and Wellness</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/usecases/agriculture">Agriculture</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/resources">Resources</Nav.Link>
            <Nav.Link as={Link} to="/Blogs">Blogs</Nav.Link>
            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}

export default Header;