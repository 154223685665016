import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link for routing

const Resources = () => {
  return (
    <div 
      style={{
        background: 'linear-gradient(to bottom, #141e30,#243b55)', // Gradient background for the entire page
        minHeight: '100vh', // Ensures the background covers the full height of the viewport
        color: '#fff', // Text color for the whole page
        padding: '2rem 0' // Additional padding for the content
      }}
    >
      <Container className="py-5">
        <h1>Resources</h1>
        <Row className="mt-4">
          <Col md={6} lg={4}>
            <Card className="mb-4" bg="dark" text="light">
              <Card.Body>
                <Card.Title>Blog</Card.Title>
                <Card.Text>
                  Stay updated with industry trends, company news, and expert insights.
                </Card.Text>
                <Button as={Link} to="/blog" variant="outline-light">Read More</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card className="mb-4" bg="dark" text="light">
              <Card.Body>
                <Card.Title>Whitepapers & Case Studies</Card.Title>
                <Card.Text>
                  Download in-depth information and examples of successful projects.
                </Card.Text>
                <Button as={Link} to="/whitepapers" variant="outline-light">Download</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4}>
            <Card className="mb-4" bg="dark" text="light">
              <Card.Body>
                <Card.Title>FAQs</Card.Title>
                <Card.Text>
                  Find answers to common questions to better understand our services.
                </Card.Text>
                <Button as={Link} to="/faqs" variant="outline-light">View FAQs</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Resources;