import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/Company/About';
import Solutions from './pages/Solutions';
import AI from './pages/Solutions/AI';
import Blockchain from './pages/Solutions/Blockchain';
import Agriculture from './pages/UseCases/Agriculture';
import FinTech from './pages/UseCases/FinTech';
import Ecommerce from './pages/UseCases/Ecommerce';
import Healthcare from './pages/UseCases/Healthcare';
import Logistics from './pages/UseCases/Logistics';
import Partners from './pages/Company/Partners';
import Careers from './pages/Company/Careers';
import Usecases from './pages/Usecases';
import Resources from './pages/Resources';
import Blogs from './pages/Blogs';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

// Import images
//import logo from './Images/logo.png'; // Example image import
//import heroImage from './Images/hero.jpg'; // Another example image import

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
                 
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/company" element={<company/>} />
		<Route path="About" element={<About/>} />
		<Route path="Careers" element={<Careers/>} />
		<Route path="Partners" element={<Partners/>} />
            <Route path="/solutions" element={<Solutions />} />
		<Route path="Solutions/AI" element={<AI/>} />
		<Route path="Solutions/Blockchain" element={<Blockchain/>} />
            <Route path="/usecases" element={<Usecases />} />
                 <Route path="Usecases/Agriculture" element={<Agriculture/>} />
		 <Route path="Usecases/FinTech" element={<FinTech/>} />
  		 <Route path="Usecases/Healthcare" element={<Healthcare/>} />
		 <Route path="Usecases/Logistics" element={<Logistics/>} />
		 <Route path="Usecases/Ecommerce" element={<Ecommerce />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/Blogs" element={<Blogs/>} />
          </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
