import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Button, Col } from 'react-bootstrap';
import innovativeImage from './Innovative.jpg';
import firefightingImage from './Firefighting.jpg';
import EmergencyManagementImage from './EmergencyManagement.jpg';
import MarineProtectionImage from './MarineProtection.jpg';

const Solution = () => {
  return (
    <div style={{
      background: 'linear-gradient(to bottom, #141e30, #243b55)',
      minHeight: '100vh',
      color: '#fff',
    }}>

      {/* Innovative Image Section */}
      <Container 
        style={{
          backgroundImage: `url(${innovativeImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%', // Full width
          height: '200px', // Set a specific height
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '5px', // optional for aesthetics
          marginBottom: '20px', // Space between image and text 
        }}
      >
        <h1 style={{ fontSize: '2rem', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px' }}>
          Innovative Tech Solutions
        </h1>
      </Container>

      <Container className="services py-5 text-center">
        <h2 className="text-center">AI Solutions</h2>

        <Row className="mb-5">
          <Col md={6}>
            <p className="justified-text">
              Our Predictive Firefighting Solutions harness the power of artificial intelligence to anticipate and mitigate fire risks before they escalate. By analyzing real-time data and environmental conditions, we empower firefighting teams with actionable insights, enabling them to deploy resources effectively and minimize damage. This innovative approach not only enhances public safety but also optimizes response times, ensuring that communities are better protected. With our cutting-edge technology, we are transforming the firefighting landscape, making it smarter and more efficient for first responders, ultimately saving lives and property.
            </p>
            <Button as={Link} to="/firefighting" variant="link" style={{ color: '#00c4ff' }}>Learn More</Button>
          </Col>
          <Col md={6}>
            <img 
              src={firefightingImage} 
              alt="Predictive Firefighting" 
              style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px' }} 
            />
          </Col>
        </Row>

        <Row className="mb-5">
          <Col md={6}>
            <p className="justified-text">
              Our Emergency Management Systems provide comprehensive solutions that integrate AI and geo-spatial analytics for efficient crisis response. Designed to support first responders and public safety officials, these systems streamline communication, resource allocation, and incident tracking. With real-time data and predictive analytics, emergency management becomes proactive rather than reactive. This technology ensures that communities can respond swiftly to disasters, enhancing safety and minimizing chaos during critical situations. Our commitment to innovation empowers local authorities to protect lives and property with unparalleled effectiveness and efficiency.
            </p>
            <Button as={Link} to="/emergency-management" variant="link" style={{ color: '#00c4ff' }}>Learn More</Button>
          </Col>
          <Col md={6}>
            <img 
              src={EmergencyManagementImage} 
              alt="Emergency Management" 
              style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px' }} 
            />
          </Col>
        </Row>

        <Row className="mb-5">
          <Col md={6}>
            <p className="justified-text">
              Our Marine and Coastal Protection Technologies leverage advanced AI and Blockchain solutions to safeguard vital marine ecosystems and coastal communities. By employing real-time monitoring and predictive analytics, we help organizations assess environmental risks and respond to challenges such as pollution, overfishing, and habitat degradation. Our technology enables effective collaboration among stakeholders, driving sustainable practices that protect marine life and promote ecological balance. With our innovative approach, we empower governments and organizations to make informed decisions that ensure the long-term health of our oceans and coastlines.
            </p>
            <Button as={Link} to="/marine-protection" variant="link" style={{ color: '#00c4ff' }}>Learn More</Button>
          </Col>
          <Col md={6}>
            <img 
              src={MarineProtectionImage} 
              alt="Marine and Coastal Protection" 
              style={{ maxWidth: '50%', height: 'auto', marginBottom: '20px' }} 
            />
          </Col>
        </Row>
        <h2>Get In Touch</h2>
        <p>Ready to Empower Your Organization? Contact us to learn how we can help you implement advanced AI solutions.</p>
        <Button as={Link} to="/contact" variant="primary">Contact Us</Button>
      </Container>
    </div>
  );
}

export default Solution;