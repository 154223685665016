import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Solutions = () => {

  const handleLearnMore = (fileUrl) => {
    window.open(fileUrl, '_blank'); // Open the file in a new tab
  };

  const solutionsData = [
    {
      title: 'GeoAI Solutions',
      text: 'Utilizing advanced AI to analyze spatial data, provide location intelligence, and process remote sensing images.',
      fileUrl:'http://10.201.5.104:1337/api/medias',
    },
    {
      title: 'EUDR Compliance',
      text: 'Real-time monitoring of supply chains, assessing deforestation risks, and ensuring adherence to EUDR regulations.',
      fileUrl: 'http://10.201.5.105:1337/api/medias',
    },
    {
      title: 'Custom Computer Vision Software Development',
      text: 'We are ready to help you add value to your business with ML algorithms for robust computer vision solutions with image analysis, object detection and video analysis.',
      fileUrl: 'http://10.201.5.105:1337/api/medias',
    },
    {
      title: 'Consulting Services',
      text: 'Expert guidance in understanding and implementing EUDR regulations, and risk management strategies.',
      fileUrl: 'http://10.201.5.105:1337/api/medias',
    },
    {
      title: 'Technology Integration',
      text: 'Incorporating geospatial analysis, blockchain for traceability, and advanced data analytics into your operations.',
      fileUrl: 'http://10.201.5.105:1337/api/medias',
    },
  ];

  return (
    <div 
     style={{
        background: 'linear-gradient(to bottom, #141e30,#243b55)', // Gradient background for the entire page
        minHeight: '100vh', // Ensures the background covers the full height of the viewport
        color: '#fff', // Text color for the whole page
      }}
     
    >
      <Container className="py-5">
        <h1>Our Solutions</h1>
        <Row className="mt-4">
          {solutionsData.map((solution, index) => (
            <Col md={6} key={index}>
              <Card className="mb-4" >
                <Card.Body>
                  <Card.Title>{solution.title}</Card.Title>
                  <Card.Text>{solution.text}</Card.Text>
                  <button 
                    className="btn btn-outline-light" 
                    onClick={() => handleLearnMore(solution.fileUrl)}
                  >
                    Learn More
                  </button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Solutions;