import React from 'react';
import { Container } from 'react-bootstrap';

const Partners = () => {
  return (
    <div 
      className="partners"
      style={{
        background: 'linear-gradient(to bottom, #141e30,#243b55)', // Gradient background
        minHeight: '100vh', // Ensures the background covers the full height of the viewport
        color: '#fff', // Text color for better visibility against the gradient
      }}
    >
      <Container className="py-5">
        
        <section className="partners">
          <h1>Our Partners</h1>
          <ul>
            <li>
              <strong>Indata Labs</strong>: A leading provider of data-driven solutions. 
              <a href="https://indatalabs.com" target="_blank" rel="noopener noreferrer"> indatalabs.com</a>
            </li>
            <li>
              <strong>Deepblock</strong>: Innovating the blockchain ecosystem through advanced technology.
              <a href="https://deepblock.net" target="_blank" rel="noopener noreferrer"> deepblock.net</a>
            </li>
            <li>
              <strong>JMT International</strong>: Delivering exceptional consulting services in various sectors.
              <a href="https://jmtinternational.com" target="_blank" rel="noopener noreferrer"> jmtinternational.com</a>
            </li>
          </ul>
        </section>
      </Container>
    </div>
  );
}

export default Partners;