import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import innovativeImage from './Innovative.jpg';

const About = () => {
  return (
    <div 
      className="About"
      style={{
        background: 'linear-gradient(to bottom, #141e30, #243b55)',
        minHeight: '100vh',
        color: '#fff',
        textAlign: 'justify',
        display: 'flex',
        flexDirection: 'column', // Change to column for vertical layout
        alignItems: 'center', // Center content horizontally
      }}
    >
      {/* Innovative Image Section */}
      <Container 
        style={{
          backgroundImage: `url(${innovativeImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%', // Full width
          height: '200px', // Set a specific height
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '5px', // optional for aesthetics
          marginBottom: '20px', // Space between image and text 
        }}
      >
        <h1 style={{ fontSize: '2rem', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px' }}>
          Innovative Tech Solutions
        </h1>
      </Container>

      {/* About Us Section */}
      <Container className="py-5" style={{ flex: 1, maxWidth: '900px' }}>
        <section className="about-section" style={{ textAlign: 'left' }}>
          <h1>About Us</h1>
          <p>
            Welcome to <strong>Al-Amen Technology Limited</strong>, a leading tech start-up based in the innovative hub of AI Campus in Dubai International Financial Centre (DIFC). We are at the forefront of merging artificial intelligence and blockchain technology, delivering cutting-edge solutions that are transforming industries and driving sustainable growth.
          </p>

          <h2>Our Mission</h2>
          <p>
            At Al-Amen Technology Limited, our mission is to harness the power of AI and blockchain to create impactful solutions that empower businesses and improve lives. We are committed to innovation, excellence, and transparency, ensuring that our clients stay ahead in an ever-evolving technological landscape.
          </p>

          <h2>Our Expertise</h2>
          <ul>
            <li><strong>AI Application Development</strong>: Our team of skilled professionals specializes in developing custom AI applications tailored to meet the unique needs of our clients...</li>
            <li><strong>Computer Vision Solutions for Agriculture</strong>: We understand the critical role that technology plays in the agricultural sector...</li>
            <li><strong>Image Analysis in Healthcare</strong>: In the healthcare industry, accurate image analysis is vital...</li>
            <li><strong>Blockchain Solutions for Supply Chain Traceability</strong>: With increasing regulations like the European Union's Deforestation Regulation (EUDR)...</li>
          </ul>

          <h2>Why Choose Us?</h2>
          <ul>
            <li><strong>Innovation-Driven</strong>: We stay ahead of technological trends...</li>
            <li><strong>Expert Team</strong>: Our diverse team of experts brings a wealth of knowledge...</li>
            <li><strong>Client-Centric Approach</strong>: We believe in building lasting partnerships...</li>
            <li><strong>Ethical Practices</strong>: Our commitment to sustainability and ethical practices...</li>
          </ul>

          <p>
            Join us on our journey to revolutionize industries with the power of AI and blockchain technology...
          </p>
          
          <h2>Get In Touch</h2>
          <p>Ready to Empower Your Organization? Contact us to learn how we can help you implement advanced AI solutions.</p>
          <Button as={Link} to="/contact" variant="primary">Contact Us</Button>
        </section>
      </Container>
    </div>
  );
}

export default About;