import React from 'react';
import { Container } from 'react-bootstrap';
import innovativeImage from './Innovative.jpg';

const Usecases = () => {
  return (
   
  <div
      className="Usecases"
      style={{
        background: 'linear-gradient(to bottom, #141e30, #243b55)',
        minHeight: '100vh',
        color: '#fff',
        textAlign: 'justify',
        display: 'flex',
        flexDirection: 'column', // Change to column for vertical layout
        alignItems: 'center', // Center content horizontally
      }}
    >
      {/* Innovative Image Section */}
      <Container
        style={{
          backgroundImage: `url(${innovativeImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%', // Full width
          height: '200px', // Set a specific height
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '5px', // optional for aesthetics
          marginBottom: '20px', // Space between image and text 
        }}
      >
        <h1 style={{ fontSize: '2rem', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px' }}>
          Innovative Tech Solutions
        </h1>
      </Container>

      <Container className="text-center" style={{ padding: '2rem 0' }}>
        <h1>AI Business Use Cases</h1>
        <p>Get a glimpse of various AI business use cases we have made. Get inspired for yours.</p>
        {/* You can add more content here regarding specific use cases */}
      </Container>
    </div>
  );
};

export default Usecases;