import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import innovativeImage from './Innovative.jpg';
import BlockchainImage from './Blockchain1.jpg';

const Blockchain = () => {
  return (
    <div style={{
      background: 'linear-gradient(to bottom, #141e30, #243b55)',
      minHeight: '100vh',
      color: '#fff',
    }}>
      {/* Innovative Image Section */}
      <Container 
        style={{
          backgroundImage: `url(${innovativeImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%',
          height: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '5px',
          marginBottom: '20px',
        }}
      >
        <h1 style={{ fontSize: '2rem', fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px' }}>
          Innovative Tech Solutions
        </h1>
      </Container>

      {/* Title Section */}
      <Container>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>
          Blockchain Solution for Supply Chain Transparency
        </h1>
        
        {/* Blockchain for EUDR Compliance Section */}
        <h2>Blockchain for EUDR Compliance</h2>
        <p className="text-justify">
          Blockchain, a decentralized and transparent technology, offers a powerful solution for overcoming the complexities of EUDR compliance.
        </p>

        {/* Transparency in Supply Chains */}
        <h3>Transparency in Supply Chains</h3>
        <p className="text-justify">
          Blockchain provides an open, tamper-proof ledger where every transaction along the supply chain is recorded. From sourcing to final destination, every step is traceable, allowing companies to prove compliance with EUDR requirements.
        </p>

        {/* Immutable Data Recording */}
        <h3>Immutable Data Recording</h3>
        <p className="text-justify">
          Once data is entered into the blockchain, it cannot be altered. This immutability is crucial for EUDR compliance, providing an unchangeable record of transactions to verify claims, such as ensuring deforestation-free products.
        </p>

        {/* Real-Time Monitoring of Supply Chains */}
        <h3>Real-Time Monitoring of Supply Chains</h3>
        <p className="text-justify">
          Blockchain enables real-time tracking of goods in the supply chain, allowing companies to ensure their suppliers comply with environmental regulations, and enabling immediate action if issues arise.
        </p>

        {/* Key Features Supporting EUDR Compliance */}
        <h3>Key Features of Blockchain That Support EUDR Compliance</h3>
        <p className="text-justify">
          Blockchain's unique characteristics, including decentralization, traceability, and smart contracts, enhance accountability and transparency.
        </p>
        <ul>
          <li><strong>Decentralization and Trust:</strong> Operates on a peer-to-peer network, enhancing reliability as no single entity controls the system.</li>
          <li><strong>Traceability and Provenance:</strong> Every step in the supply chain is recorded, essential for proving that goods are sourced from deforestation-free areas.</li>
          <li><strong>Smart Contracts for Automated Compliance:</strong> Automatically verify regulatory adherence, reducing manual checks and ensuring compliance.</li>
        </ul>

        {/* Blockchain for EUDR Compliance in Key Industries */}
        <h3>Blockchain for EUDR Compliance in Key Industries</h3>
        <p className="text-justify">
          Certain industries can benefit significantly from blockchain in achieving EUDR compliance.
        </p>
        <ul>
          <li><strong>Agriculture and Forest Products:</strong> Ensures crops like soy and timber are sourced responsibly.</li>
          <li><strong>Rubber and Palm Oil:</strong> Provides transparency and traceability for commodities heavily scrutinized for deforestation impacts.</li>
          <li><strong>Coffee and Cocoa Supply Chains:</strong> Simplifies compliance across complex networks of intermediaries.</li>
        </ul>
        
        <h2>Get In Touch</h2>
        <p className="text-justify">
          Ready to Empower Your Organization? Contact us to learn how we can help you implement advanced AI solutions.
        </p>
        <Button as={Link} to="/contact" variant="primary">Contact Us</Button>
      </Container>
    </div>
  );
};

export default Blockchain;