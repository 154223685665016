import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Row, Col } from 'react-bootstrap';
import AlApplicationImage from './Images/AlApplication.jpg';
import innovativeImage from './Innovative.jpg';
import blockchainImage from './Images/blockchain.jpg';
import computervisionImage from './Images/computervision.jpg';
import videoMessage from './videoImage.mp4'; 

const Home = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  return (
    <div 
      className="home"
      style={{
        background: 'linear-gradient(to bottom, #141e30, #243b55, rgba(20, 30, 48, 0.7), rgba(36, 59, 85, 0.7))',
        minHeight: '100vh',
        color: '#fff',
        textAlign: 'justify',
      }}
    >
      {/* Hero Section */}
      <Container 
        className="text-center"
        style={{
          backgroundImage: `url(${innovativeImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '5px',
          marginBottom: '30px',
          padding: '20px',
        }}
      >
        <h1 style={{ fontSize: '2rem', fontWeight: 'bold' }}>Innovative Tech Solutions</h1>
      </Container>

      {/* Video Message Section */}
      <Container className="text-center my-5">
        <h2 style={{ marginBottom: '20px' }}>Watch Our Message</h2>
        {!isPlaying ? (
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <img 
              src="path/to/thumbnail.jpg"
              alt="Thumbnail"
              style={{ width: '80%', borderRadius: '5px' }} 
            />
            <Button
              variant="success"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '1.2rem', // Increased font size for visibility
                padding: '15px 30px', // Increased padding for larger button
              }}
              onClick={handlePlayClick}
            >
              Play Video
            </Button>
          </div>
        ) : (
          <video 
            width="80%" 
            controls 
            style={{ borderRadius: '5px' }}
            autoPlay
          >
            <source src={videoMessage} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </Container>

      <Container className="mt-5">
        <Row>
          <Col md={4} className="text-center mb-4">
            <img src={AlApplicationImage} alt="AI Application Development" style={{ width: '70%', height: 'auto' }} />
            <h4 style={{ fontWeight: 'bold' }}>AI Application Development</h4>
            <p>Custom AI applications tailored to your business needs.</p>
            <p style={{ textAlign: 'justify' }}>
              At Al-Amen Technology, we specialize in developing bespoke AI applications that align with your unique business requirements. 
              Our skilled professionals utilize advanced algorithms and innovative machine learning techniques to create solutions that enhance operational efficiency and drive informed decision-making. 
            </p>
            <Button as={Link} to="/Solutions/ai" variant="link" style={{ color: '#00c4ff' }}>Learn More</Button>
          </Col>
          <Col md={4} className="text-center mb-4">
            <img src={computervisionImage} alt="Computer Vision Solutions" style={{ width: '70%', height: 'auto' }} />
            <h4 style={{ fontWeight: 'bold' }}>Computer Vision Solutions</h4>
            <p>Precision agriculture tools for modern farmers.</p>
            <p style={{ textAlign: 'justify' }}>
              Transform your agricultural practices with our cutting-edge computer vision solutions. 
              At Al-Amen Technology, we empower farmers with state-of-the-art tools designed for precision agriculture. 
              Our technology enables real-time monitoring of crops, health analysis, and yield optimization, providing farmers with actionable insights to enhance productivity and sustainability. 
            </p>
       <Button as={Link} to="/computer-vision" variant="link" style={{ color: '#00c4ff' }}>Learn More</Button>
          </Col>
          <Col md={4} className="text-center mb-4">
            <img src={blockchainImage} alt="Blockchain Solutions" style={{ width: '70%', height: 'auto' }} />
            <h4 style={{ fontWeight: 'bold' }}>Blockchain Solutions for Supply Chain Traceability</h4>
            <p>Enhance transparency and accountability in supply chains.</p>
            <p style={{ textAlign: 'justify' }}>
              In a world where transparency is paramount, our blockchain solutions offer unparalleled traceability for supply chains. 
              At Al-Amen Technology, we understand the complexities of modern supply chains and the importance of responsible sourcing. 
            </p>
            <Button as={Link} to="/Solutions/Blockchain" variant="link" style={{ color: '#00c4ff' }}>Learn More</Button>
          </Col>
        </Row>
      </Container>

      {/* Customer Success Stories */}
      <Container className="success-stories py-5 text-center">
        <h2>Customer Success Stories</h2>
        <p>?? Hear from our partners about how AI-Amen Technology transformed their approach to safety and sustainability.</p>
      </Container>

      {/* Get In Touch */}
      <Container className="get-in-touch py-5 text-center">
        <h2>Get In Touch</h2>
        <p>Ready to Empower Your Organization?</p>
        <p>Contact us to learn how we can help you implement advanced AI solutions.</p>
        <Button as={Link} to="/contact" variant="primary">Contact Us</Button>
      </Container>

    </div>
  );
}

export default Home;
